import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateApiKey, useUpdateApiKey } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { formatDateWithCurrentLocale } from '@src/i18n/datefns';
import { ApiKeyDto, CreateAndUpdateApiKeyDto, ResourceType } from '@src/models';

import { Button, Modal, RequiredMarker, StyledA } from '../common';

export const AddApiKeyModal = ({
  visible,
  handleClose,
  editKey
}: {
  visible: boolean;
  handleClose: () => void;
  editKey?: ApiKeyDto;
}) => {
  return (
    <Modal visible={visible} close={handleClose}>
      <AddApiKeyModalContent handleClose={handleClose} editKey={editKey} />
    </Modal>
  );
};

const calculateExpirationDate = (selectedExpiration: number) => {
  const date = new Date();
  const expirationDate = new Date();
  expirationDate.setDate(date.getDate() + selectedExpiration);
  return expirationDate;
};

const AddApiKeyModalContent = ({ handleClose, editKey }: { handleClose: () => void; editKey?: ApiKeyDto }) => {
  const { t } = useTranslation();
  const expirationOptions = [0, 30, 60, 90];
  const [apiKey, setApiKey] = useState<CreateAndUpdateApiKeyDto>({
    nickname: editKey?.nickname || '',
    expiryDate: editKey?.expiryDate,
    limits: { ...editKey?.limits }
  });
  const [selectedExpiration, setSelectedExpiration] = useState<number>(expirationOptions[0]);
  const [disableExpiration, setDisableExpiration] = useState(!!editKey);

  const canGenerate = apiKey.nickname.length > 0;

  const { isLoading: loadingCreate, mutateAsync: createApiKey } = useCreateApiKey();
  const { isLoading: loadingEdit, mutateAsync: updateApiKey } = useUpdateApiKey();

  const isLoading = loadingCreate || loadingEdit;

  const onLimitsUpdate = useCallback(
    (key: ResourceType, value: number) => {
      const newApiKey = {
        ...apiKey,
        limits: { ...apiKey?.limits, [key]: value }
      };
      if (!value && value !== 0) {
        delete newApiKey.limits[key];
      }

      setApiKey(newApiKey);
    },
    [apiKey]
  );

  const generateKey = async (e: FormEvent) => {
    e.preventDefault();
    if (editKey) {
      await updateApiKey({ id: editKey.id, apiKey });
    } else {
      await createApiKey(apiKey);
    }
    handleClose();
  };

  useEffect(() => {
    const expirationCalculated = calculateExpirationDate(selectedExpiration).toISOString();

    if (!editKey) {
      selectedExpiration && setApiKey(prev => ({ ...prev, expiryDate: expirationCalculated }));
      return;
    }

    if (disableExpiration) {
      // in case of "Update" and then "Dismiss" so we reset to old one
      setApiKey(prev => ({ ...prev, expiryDate: editKey.expiryDate }));
    } else {
      const newDate = selectedExpiration === 0 ? undefined : expirationCalculated;
      setApiKey(prev => ({ ...prev, expiryDate: newDate }));
    }
  }, [disableExpiration, editKey, selectedExpiration]);

  return (
    <form className="space-y-8" onSubmit={e => generateKey(e)}>
      <div>
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {t('components.user.AddApiKeyModal.title')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">{t('components.user.AddApiKeyModal.description')}</p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              {t('components.user.common.nickname')}
              <RequiredMarker />
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="name"
                id="name"
                autoComplete="name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={apiKey.nickname}
                onChange={e => setApiKey({ ...apiKey, nickname: e.target.value })}
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              {t('components.user.AddApiKeyModal.expirationLabel')}
            </label>
            <select
              disabled={disableExpiration}
              value={selectedExpiration}
              onChange={e => setSelectedExpiration(parseInt(e.target.value))}
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6"
            >
              {expirationOptions.map((opt, index) => (
                <option key={index} value={opt} className="w-full">
                  {t('components.user.AddApiKeyModal.expiration', { context: opt })}
                </option>
              ))}
            </select>
            <p className="mt-1 text-sm text-gray-500">
              {disableExpiration
                ? t('components.user.AddApiKeyModal.noExpirationUpdate')
                : selectedExpiration !== 0
                  ? t('components.user.AddApiKeyModal.expirationMessage', {
                      date: formatDateWithCurrentLocale(
                        calculateExpirationDate(selectedExpiration),
                        localizationHelper.getLocale(),
                        'PPpp'
                      )
                    })
                  : t('components.user.AddApiKeyModal.noExpirationMessage')}{' '}
              {editKey && (
                <StyledA
                  onClick={() => {
                    const disabled = !disableExpiration;
                    setDisableExpiration(disabled);
                    if (disabled) {
                      setSelectedExpiration(expirationOptions[0]);
                    }
                  }}
                  className="cursor-pointer"
                >
                  {disableExpiration ? t('general.action.update') : t('general.action.close')}
                </StyledA>
              )}
            </p>
          </div>
          <div className="sm:col-span-6">
            <label htmlFor="storage" className="block text-sm font-medium leading-6 text-gray-900">
              {t('components.user.AddApiKeyModal.limitLabel')}
            </label>
            <p className="mt-1 text-sm text-gray-500">{t('components.user.AddApiKeyModal.limitDescription')}</p>
            <div className="flex w-full space-x-2">
              <div className="mt-2 w-full">
                <p className="text-sm font-medium text-gray-500">{t('components.common.storage')}</p>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="number"
                    name="storage"
                    id="storage"
                    min={0}
                    className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    aria-describedby="storage-mb"
                    defaultValue={apiKey.limits?.STORAGE ? apiKey.limits.STORAGE / (1024 * 1024) : undefined}
                    onChange={e => {
                      const value = parseInt(e.target.value);
                      value >= 0
                        ? onLimitsUpdate(ResourceType.STORAGE, parseInt(e.target.value) * 1024 * 1024)
                        : onLimitsUpdate(ResourceType.STORAGE, NaN);
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span className="text-gray-500 sm:text-sm" id="storage-mb">
                      {t('components.user.AddApiKeyModal.mb')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 w-full">
              <p className="text-sm font-medium text-gray-500">
                {t('components.user.AddApiKeyModal.renderingMinutesLabel')}
              </p>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="number"
                  name="rendering-minutes"
                  id="rendering-minutes"
                  min={0}
                  className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  aria-describedby="rendering-minutes"
                  defaultValue={
                    apiKey.limits?.RENDERING_VIDEO_DURATION ? apiKey.limits.RENDERING_VIDEO_DURATION / 60 : undefined
                  }
                  onChange={e => {
                    const value = parseInt(e.target.value);
                    value >= 0
                      ? onLimitsUpdate(ResourceType.RENDERING_VIDEO_DURATION, parseInt(e.target.value) * 60)
                      : onLimitsUpdate(ResourceType.RENDERING_VIDEO_DURATION, NaN);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm" id="rendering-minutes">
                    {t('components.user.AddApiKeyModal.min')}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-2 w-full">
              <p className="text-sm font-medium text-gray-500">
                {t('components.user.AddApiKeyModal.videoGeniusVideosLabel')}
              </p>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="number"
                  name="videoGenius-videos"
                  id="videoGenius-videos"
                  min={0}
                  className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  aria-describedby="videoGenius-videos"
                  defaultValue={apiKey.limits?.ARTICLE_VIDEOS}
                  onChange={e => {
                    const value = parseInt(e.target.value);
                    value >= 0
                      ? onLimitsUpdate(ResourceType.ARTICLE_VIDEOS, value)
                      : onLimitsUpdate(ResourceType.ARTICLE_VIDEOS, NaN);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm" id="videoGenius-videos">
                    {t('components.user.AddApiKeyModal.amt')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button type="button" secondary className="mr-2" onClick={handleClose}>
          {t('general.action.cancel')}
        </Button>
        <Button type="submit" disabled={!canGenerate || isLoading} loading={isLoading}>
          {t('general.action.save')}
        </Button>
      </div>
    </form>
  );
};
