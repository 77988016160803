import { lazy, Suspense } from 'react';

import { Loading } from '@src/components';
import { State, useGlobalState } from '@src/state/store';

const PickerOverlay = lazy(() => import('filestack-react').then(module => ({ default: module.PickerOverlay })));

export const FilestackUpload = ({
  uploadOpen,
  onClose,
  onFieldUpdate,
  onFileUploadFailed,
  accept
}: {
  uploadOpen: boolean;
  onClose: () => void;
  onFieldUpdate: (value: string) => void;
  onFileUploadFailed?: (value: string) => void;
  accept?: string[];
}) => {
  const [user] = useGlobalState(State.USER);

  return (
    <>
      {uploadOpen && (
        <Suspense fallback={<Loading />}>
          <PickerOverlay
            apikey={import.meta.env.VITE_APP_FILE_STACK_API_KEY!}
            clientOptions={{
              sessionCache: true
            }}
            pickerOptions={{
              accept: accept ? accept : ['image/*', 'video/*', 'audio/*'],
              fromSources: [
                'local_file_system',
                'imagesearch',
                'unsplash',
                'googledrive',
                'facebook',
                'instagram',
                'webcam',
                'video',
                'dropbox',
                'box',
                'onedrive',
                'onedriveforbusiness'
              ],
              maxFiles: 1,
              maxSize: 100 * 1024 * 1024,
              uploadConfig: {
                tags: {
                  userNickname: user?.nickname || ''
                }
              },
              onClose: () => onClose(),
              onFileUploadFinished: res => res && onFieldUpdate(res.url),
              onFileUploadFailed: res => res && onFileUploadFailed && onFileUploadFailed(res.url)
            }}
          />
        </Suspense>
      )}
    </>
  );
};

export default FilestackUpload;
