import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddApiKeyModal, ApiKeyItem, Button, ConfirmationModal, CopyIdAction, UpdatableString } from '@src/components';
import { useDeleteApiKey, useEditUserDetails, useGetApiKeys } from '@src/hooks';
import { ApiKeyDto } from '@src/models';
import { State, useGlobalState } from '@src/state/store';

export const OrganizationCard = () => {
  const { t } = useTranslation();
  const { data: apiKeys = [] } = useGetApiKeys();
  const { isLoading: isLoadingUpdate, mutateAsync: updateOrganization } = useEditUserDetails();
  const { isLoading: isLoadingDelete, mutateAsync: deleteApiKey } = useDeleteApiKey();
  const [activeOrganization] = useGlobalState(State.ORGANIZATION);
  const [showApiKeyModal, setShowApiKeyModal] = useState<boolean>(false);
  const [editKeyData, setEditKeyData] = useState<ApiKeyDto>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<string>();

  const isLoading = isLoadingUpdate || isLoadingDelete;

  const updateOrgName = useCallback(
    async (value: string) => {
      await updateOrganization({ organizationName: value });
    },
    [updateOrganization]
  );

  const deleteCallback = useCallback(
    async (id: string) => {
      await deleteApiKey(id);
      setShowDeleteConfirmation(undefined);
    },
    [deleteApiKey]
  );

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('general.common.organization')}</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('components.user.UserOrganizationCard.subtitle')}</p>
          </div>
          <div className="ml-4 mt-2 shrink-0">
            <Button onClick={() => setShowApiKeyModal(true)}>
              {t('components.user.UserOrganizationCard.addNewApiKey')}
            </Button>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {activeOrganization && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{t('components.common.organizationName')}</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <UpdatableString value={activeOrganization.name} loading={isLoading} onUpdate={updateOrgName} />
                <CopyIdAction className="mt-1" id={activeOrganization.id} type="organization" />
              </dd>
            </div>
          )}
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">{t('components.user.UserOrganizationCard.apiKeys')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {t('components.user.UserOrganizationCard.apiKeysDescription')}
            </dd>
            {apiKeys && (
              <dd className="mt-3 text-sm text-gray-900">
                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                  {apiKeys.map((apiKey, index) => (
                    <ApiKeyItem
                      key={`apikey-${index}`}
                      apiKey={apiKey}
                      edit={() => setEditKeyData(apiKey)}
                      deleteKey={() => setShowDeleteConfirmation(apiKey.id)}
                    />
                  ))}
                </ul>
              </dd>
            )}
          </div>
        </dl>
      </div>
      <AddApiKeyModal
        visible={showApiKeyModal || editKeyData !== undefined}
        editKey={editKeyData}
        handleClose={() => {
          setEditKeyData(undefined);
          setShowApiKeyModal(false);
        }}
      />
      <ConfirmationModal
        title={t('components.user.UserOrganizationCard.deleteApiKeyTitle')}
        description={t('components.user.UserOrganizationCard.deleteApiKeySubtitle')}
        visible={showDeleteConfirmation !== undefined}
        isLoading={false}
        onConfirm={() => deleteCallback(showDeleteConfirmation!)}
        onClose={() => setShowDeleteConfirmation(undefined)}
        danger
      />
    </div>
  );
};
