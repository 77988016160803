import { useTranslation } from 'react-i18next';

import { StyledA } from '@src/components/common';

type UpdateParam =
  | {
      isColumnSelect: true;
      value: string | undefined;
    }
  | {
      isColumnSelect: false;
      value: boolean | undefined;
    };

export const LayerMngEnabledInput = ({
  value,
  onFieldUpdate,
  isColumnSelect,
  columns
}: {
  value: string | boolean | undefined;
  onFieldUpdate: (param: UpdateParam) => void;
  isColumnSelect: boolean;
  columns?: string[];
}) => {
  const { t } = useTranslation();
  const fieldValue = value || '';
  const batchRender = columns != undefined;

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case 'undefined':
        onFieldUpdate({ value: undefined, isColumnSelect: false });
        break;
      case 'true':
        onFieldUpdate({ value: true, isColumnSelect: false });
        break;
      case 'false':
        onFieldUpdate({ value: false, isColumnSelect: false });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <label htmlFor="enabled" className="block text-sm font-medium text-gray-700">
        {t('components.render.dynamicScripts.LayerMngEnabledInput.enabled')}
      </label>
      {isColumnSelect === true ? (
        <select
          id="enabled"
          className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-sm text-gray-900 focus:ring-indigo-600"
          onChange={e => onFieldUpdate({ value: e.target.value, isColumnSelect: true })}
          value={fieldValue as string}
        >
          <option disabled={true} value={''}>
            {t('components.render.RenderBatchTable.ColumnSelectField.selectColumn')}
          </option>
          {columns?.map((column, index) => (
            <option key={index} value={column}>
              {column}
            </option>
          ))}
        </select>
      ) : (
        <div>
          <select
            id="enabled"
            name="enabled"
            value={value === undefined ? 'undefined' : value ? 'true' : 'false'}
            className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-sm text-gray-900 focus:ring-indigo-600"
            onChange={onChange}
          >
            <option key="enabled-undefined" value="undefined">
              {t('general.action.select')}
            </option>
            <option key="enabled-true" value="true">
              {t('general.common.yes')}
            </option>
            <option key="enabled-false" value="false">
              {t('general.common.no')}
            </option>
          </select>
        </div>
      )}
      {batchRender && (
        <StyledA
          onClick={() => onFieldUpdate({ value: undefined, isColumnSelect: !isColumnSelect })}
          className="float-right mt-1 block cursor-pointer text-sm font-medium text-gray-700"
        >
          {t('components.render.RenderBatchTable.ColumnSelectField.columnFieldSwitch', {
            context: `${isColumnSelect}`
          })}
        </StyledA>
      )}
    </>
  );
};
