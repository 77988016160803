import { useCallback, useReducer } from 'react';

import { useLocalStorage } from '../useLocalStorage';

export enum View {
  Grid = 'grid',
  List = 'list'
}

type State = {
  view: View;
  sidebarDesktopCollapsed: boolean;
  sidebarDesktopFullyCollapsed: boolean;
  latestViewedReleaseNotesVersion?: string;
  pluginViewed?: true;
};

type Action =
  | {
      type: 'set-view';
      payload: {
        view: View.Grid | View.List;
      };
    }
  | {
      type: 'toggle-sidebar';
      payload: {
        sidebarDesktopCollapsed: boolean;
        sidebarDesktopFullyCollapsed: boolean;
      };
    }
  | {
      type: 'set-release-notes';
      payload: {
        latestViewedReleaseNotesVersion?: string;
      };
    }
  | {
      type: 'set-plugin';
      payload: {
        pluginViewed?: true;
      };
    };

const initialState: State = {
  view: View.List,
  sidebarDesktopCollapsed: false,
  sidebarDesktopFullyCollapsed: false,
  latestViewedReleaseNotesVersion: undefined,
  pluginViewed: undefined
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set-view':
      return { ...state, view: action.payload.view };
    case 'toggle-sidebar':
      return {
        ...state,
        sidebarDesktopCollapsed: action.payload.sidebarDesktopCollapsed,
        sidebarDesktopFullyCollapsed: action.payload.sidebarDesktopFullyCollapsed
      };
    case 'set-release-notes':
      return { ...state, latestViewedReleaseNotesVersion: action.payload.latestViewedReleaseNotesVersion };
    case 'set-plugin':
      return { ...state, pluginViewed: true };
    default:
      return state;
  }
}

export const useAppSettingsReducer = () => {
  const { storedValue: settings, setItem: setSettings } = useLocalStorage('plainly.app-settings', initialState);
  const [state, dispatch] = useReducer(reducer, settings);

  const setView = useCallback(
    (view: View.Grid | View.List) => {
      dispatch({ type: 'set-view', payload: { view } });
      setSettings({ ...state, view });
    },
    [setSettings, state]
  );

  const toggleSidebar = useCallback(
    (sidebarDesktopCollapsed: boolean, sidebarDesktopFullyCollapsed: boolean) => {
      dispatch({ type: 'toggle-sidebar', payload: { sidebarDesktopCollapsed, sidebarDesktopFullyCollapsed } });
      setSettings({ ...state, sidebarDesktopCollapsed, sidebarDesktopFullyCollapsed });
    },
    [setSettings, state]
  );

  const setLatestViewedReleaseNotes = useCallback(
    (latestViewedReleaseNotesVersion?: string) => {
      dispatch({ type: 'set-release-notes', payload: { latestViewedReleaseNotesVersion } });
      setSettings({ ...state, latestViewedReleaseNotesVersion });
    },
    [setSettings, state]
  );

  const setPluginViewed = useCallback(
    (pluginViewed?: true) => {
      dispatch({ type: 'set-plugin', payload: { pluginViewed } });
      setSettings({ ...state, pluginViewed });
    },
    [setSettings, state]
  );

  return { settings: state, setView, toggleSidebar, setLatestViewedReleaseNotes, setPluginViewed };
};
