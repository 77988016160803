import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { encode } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  MinusCircleIcon,
  SignalIcon
} from '@heroicons/react/20/solid';
import { useError, useFireRender, useQueryParams } from '@src/hooks';
import { PlainlyPackage, ProjectRenderDto, Render, RenderOptionsDto, RenderState, Webhook } from '@src/models';
import * as routes from '@src/routes';
import { RENDER_DETAILS } from '@src/routes';
import { BatchRenderPassthrough, SelectedDynamicScripts } from '@src/types';
import { addAdvancedOptions, convertDotsToNested, isEmpty } from '@src/utils';

import { Button, StyledLink } from '../common';
import { FeatureGating } from '../featureGating';

import { handleLayerManagementParams } from './dynamicScripts';
import { RenderBatchParametrizationModal } from './RenderBatchParametrizationModal';

type RenderResult = {
  skipped?: boolean;
  render?: Render;
  error?: Error;
  loading?: boolean;
};

type RenderBatchPreviewTableProps = {
  data: Record<string, string>[];
  selectedColumns: { [param: string]: { value: string; isColumnSelect: boolean } };
  webhookPassthrough: BatchRenderPassthrough;
  integrationsPassthrough: BatchRenderPassthrough;
  advancedOptions?: RenderOptionsDto;
  selectedDynamicScripts?: SelectedDynamicScripts;
  projectId: string;
  templateId: string;
  batchRenderId?: string;
  previousStep: () => void;
  cleanup: () => void;
};

const getPassthroughArray = (
  data: Record<string, string>,
  passthrough: { [param: string]: { value: string; isColumnSelect: boolean } }
) => {
  const params: Record<string, string> = {};
  for (const param in passthrough) {
    if (passthrough[param].isColumnSelect) {
      params[param] = data[passthrough[param].value];
    } else {
      params[param] = passthrough[param].value;
    }
  }
  return params;
};

export const RenderBatchPreviewTable = ({
  data,
  selectedColumns,
  webhookPassthrough,
  integrationsPassthrough,
  advancedOptions,
  selectedDynamicScripts,
  projectId,
  templateId,
  batchRenderId,
  previousStep,
  cleanup
}: RenderBatchPreviewTableProps) => {
  const { t } = useTranslation();
  const { formatMessage } = useError();
  const { withQueryParams, searchQuery } = useQueryParams();
  const isDesign = searchQuery.get('design') === 'true';

  const { mutateAsync: postRender } = useFireRender({
    silentNotifications: true,
    throwOnError: true
  });

  const [results, setResults] = useState<RenderResult[]>([]);
  const [modalState, setModalState] = useState({
    show: false,
    renderIndex: -1
  });
  const loading = results.some(r => r.loading);
  const rendersNotFired = !results.length && !loading;

  const handleColors = (key: string, value: string) => {
    if (isDesign && ['colorPrimary', 'colorSecondary', 'colorTertiary'].includes(key) && value.startsWith('#')) {
      return value.substring(1);
    }
    return value;
  };

  const paramsArray = data.map(d => {
    let params: Record<string, string | Record<string, string | number | boolean>> = {};
    for (const column in selectedColumns) {
      if (selectedColumns[column].isColumnSelect) {
        params[column] = handleColors(column, d[selectedColumns[column].value]);
      } else {
        if (selectedColumns[column].value) {
          params[column] = handleColors(column, selectedColumns[column].value);
        }
      }
    }

    for (const column in selectedDynamicScripts) {
      const properties = Object.keys(selectedDynamicScripts[column]).map(p => ({
        [p]: selectedDynamicScripts[column][p]
      }));
      let paramsColumn = params[column] as { [x: string]: string | number | readonly string[] | boolean };

      for (const property of properties) {
        const key = Object.keys(property)[0];

        if (property[key].value !== undefined) {
          if (property[key].isColumnSelect) {
            const value = handleLayerManagementParams(key, d[property[key].value as string]);
            if (value !== undefined) {
              paramsColumn = {
                ...paramsColumn,
                [key]: value
              };
            }
          } else {
            const value = property[key].value;
            if (value !== undefined && value !== '') {
              paramsColumn = {
                ...paramsColumn,
                [key]: value
              };
            }
          }
        }
      }

      params = {
        ...params,
        [column]: paramsColumn
      } as Record<string, string | Record<string, string | number | boolean>>;
    }

    return convertDotsToNested(params);
  });

  const webhookArray = data.map(d => getPassthroughArray(d, webhookPassthrough.passthrough));
  const integrationsArray = data.map(d => getPassthroughArray(d, integrationsPassthrough.passthrough));

  const getRenders = () => {
    const renders = [];

    for (let i = 0; i < data.length; i++) {
      if (results[i]) return;
      const options: RenderOptionsDto = {
        ...advancedOptions,
        webhook: advancedOptions?.webhook?.url
          ? {
              url: advancedOptions.webhook.url,
              passthrough: webhookPassthrough.sendAsJson
                ? JSON.stringify(webhookArray[i])
                : Object.values(webhookArray[i])[0],
              onFailure: advancedOptions.webhook.onFailure,
              onInvalid: advancedOptions.webhook.onInvalid
            }
          : (advancedOptions?.webhook as Webhook),
        options: {
          ...advancedOptions?.options,
          integrations: {
            ...advancedOptions?.options?.integrations,
            passthrough: advancedOptions?.options?.integrations?.skipAll
              ? undefined
              : integrationsPassthrough.sendAsJson
                ? JSON.stringify(integrationsArray[i])
                : Object.values(integrationsArray[i])[0]
          }
        }
      };

      const attributes = batchRenderId
        ? {
            batchRenderId,
            batchRenderSequenceNo: i + 1
          }
        : undefined;

      const projectRender: ProjectRenderDto = {
        projectId,
        templateId,
        parameters: paramsArray[i],
        attributes
      };
      const render = addAdvancedOptions(projectRender, options);

      renders.push(render);
    }
    return renders;
  };

  const getStatus = (index: number) => {
    if (!results[index]) {
      return (
        <span className="flex">
          <ClockIcon className="mr-2 h-5 w-5" />
          {t('components.render.RenderBatchPreviewTable.status', { context: 'READY' })}
        </span>
      );
    } else if (results[index].loading) {
      return (
        <span className="flex">
          <SignalIcon className={classNames('mr-2 h-5 w-5 animate-spin')} />
          {t('general.common.loading')}
        </span>
      );
    } else if (results[index].error) {
      const error = results[index].error;
      return (
        <span className="flex">
          <ExclamationCircleIcon className="mr-2 h-5 w-5 text-red-500" title={error && formatMessage(error)} />
          {t('components.render.RenderBatchPreviewTable.status', { context: 'ERROR' })}
        </span>
      );
    } else if (results[index].render && results[index].render?.state === RenderState.FAILED) {
      const msg = results[index].render?.error?.message
        ? JSON.stringify(results[index].render?.error?.message)
        : JSON.stringify(results[index].render?.error);
      return (
        <span className="flex">
          <ExclamationCircleIcon className="mr-2 h-5 w-5 text-red-500" title={msg} />
          {t('components.render.RenderBatchPreviewTable.status', { context: RenderState.FAILED })}
        </span>
      );
    } else if (results[index].render && results[index].render?.state === RenderState.INVALID) {
      const msg = results[index].render?.error?.message
        ? JSON.stringify(results[index].render?.error?.message)
        : JSON.stringify(results[index].render?.error);
      return (
        <span className="flex">
          <ExclamationTriangleIcon className="mr-2 h-5 w-5 text-yellow-500" title={msg} />
          {t('components.render.RenderBatchPreviewTable.status', { context: RenderState.INVALID })}
        </span>
      );
    } else if (results[index].skipped) {
      return (
        <span className="flex">
          <MinusCircleIcon className="text-grey-500 mr-2 h-5 w-5" />
          {t('components.render.RenderBatchPreviewTable.status', { context: 'SKIPPED' })}
        </span>
      );
    } else {
      return (
        <span className="flex">
          <CheckCircleIcon className="mr-2 h-5 w-5 text-green-500" />
          {t('components.render.RenderBatchPreviewTable.status', { context: 'SUCCESS' })}
        </span>
      );
    }
  };

  const fireRender = () => {
    const renders = getRenders();
    if (!isEmpty(renders)) {
      const loadingStates: RenderResult[] = [];
      renders.forEach(
        (r, index) => (loadingStates[index] = skipRenders[index] === false ? { loading: true } : { skipped: true })
      );
      setResults(loadingStates);

      renders.forEach((render, i) => {
        if (!skipRenders[i]) {
          postRender(render)
            .then(res => {
              if (res) {
                loadingStates[i] = { loading: false, render: res };
                setResults([...loadingStates]);
              }
            })
            .catch(e => {
              loadingStates[i] = { loading: false, error: e };
              setResults([...loadingStates]);
            });
        }
      });
    }
  };

  const renders = getRenders();
  const [skipRenders, setSkipRenders] = useState<boolean[]>(new Array<boolean>(renders?.length || 0).fill(false));

  useEffect(() => {
    return () => {
      if (!rendersNotFired) {
        cleanup();
      }
    };
  }, [rendersNotFired, cleanup]);

  const renderForParamsModal = useMemo(() => renders?.[modalState.renderIndex], [modalState.renderIndex, renders]);

  return (
    <>
      <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300 py-2">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="relative py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                #
              </th>
              <th
                scope="col"
                className="relative hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                {t('components.render.RenderBatchPreviewTable.columnData')}
              </th>
              <th scope="col" className="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                {t('general.common.status')}
              </th>
              <th scope="col" className="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                <span className="sr-only">{t('components.render.RenderBatchPreviewTable.link')}</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data.map((d, index) => (
              <tr key={index}>
                <td className="relative whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                  <input
                    id={`exclude-render-${index}`}
                    title={t('components.render.RenderBatchPreviewTable.excludeRendersTitle')}
                    type="checkbox"
                    className="mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
                    disabled={!rendersNotFired}
                    defaultChecked={true}
                    onChange={e => {
                      const newSkipRenders = [...skipRenders];
                      newSkipRenders[index] = e.target.checked ? false : true;
                      setSkipRenders(newSkipRenders);
                    }}
                  />
                  <label htmlFor={`exclude-render-${index}`} className="ml-3 min-w-0 flex-1">
                    {index + 1}
                  </label>
                </td>
                <td
                  className="hidden w-[40rem] cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                  title={Object.values(d).join('  , ')}
                >
                  <p className="w-[40rem] truncate">{Object.values(d).join(', ')}</p>
                </td>
                <td className="relative whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500">
                  {getStatus(index)}
                </td>
                <td className="relative px-3 py-4 text-right text-sm text-gray-500">
                  {results[index]?.render ? (
                    <StyledLink to={generatePath(RENDER_DETAILS, { id: results[index].render?.id })}>
                      {t('components.render.RenderBatchPreviewTable.renderDetails')}
                    </StyledLink>
                  ) : (
                    !results[index]?.skipped && (
                      <span
                        className="inline-flex cursor-pointer items-center font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        onClick={() => setModalState({ show: true, renderIndex: index })}
                      >
                        {t('components.render.RenderBatchPreviewTable.preview')}
                      </span>
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end">
        {rendersNotFired ? (
          <>
            <Button secondary className="mr-3" onClick={previousStep}>
              {t('general.action.back')}
            </Button>
            <FeatureGating
              enabledPackages={[PlainlyPackage.STARTER, PlainlyPackage.TEAM, PlainlyPackage.PRO]}
              freeTrialAllowed={false}
              disabled={data.length < 10}
              message={t('components.render.RenderBatchPreviewTable.renderNotAllowedMessage')}
            >
              <Button onClick={fireRender} loading={loading}>
                {t('general.action.render')}
              </Button>
            </FeatureGating>
          </>
        ) : (
          <Link
            to={withQueryParams(generatePath(routes.RENDER_BATCH_DOWNLOAD, { step: '2' }), {
              batchRender: encode(JSON.stringify({ batchRenderId: batchRenderId }))
            })}
          >
            <Button type="button" loading={loading} disabled={loading} className="w-full sm:w-auto">
              {t('components.render.common.batchDownload')}
            </Button>
          </Link>
        )}
      </div>
      <RenderBatchParametrizationModal
        showModal={modalState.show}
        onClose={() => setModalState({ ...modalState, show: false })}
        render={renderForParamsModal}
      />
    </>
  );
};
