import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import {
  ArrowPathRoundedSquareIcon as ArrowPath,
  BoltIcon as IntegrationsIcon,
  ChartBarIcon as DashboardIcon,
  CogIcon as SettingsIcon,
  FilmIcon as DesignsIcon,
  NewspaperIcon as ArticleIcon,
  QueueListIcon as ProjectsIcon,
  TagIcon as BrandsIcon,
  VideoCameraIcon as RendersIcon
} from '@heroicons/react/24/outline';
import { SidebarItem, SidebarItemProps } from '@src/components';
import { PLAINLY_PLUGIN_CREATIVE_CLOUD_LINK } from '@src/constants';
import { isProd, releaseNotesHash } from '@src/env';
import { useAppSettingsReducer } from '@src/hooks';
import * as routes from '@src/routes';

export const SidebarLinks = React.memo(function SidebarLinks({
  toggleSideBar,
  sidebarDesktopFullyCollapsed
}: {
  toggleSideBar?: () => void;
  sidebarDesktopFullyCollapsed?: boolean;
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const { settings, setLatestViewedReleaseNotes, setPluginViewed } = useAppSettingsReducer();

  const publisherView = location.pathname.includes('/video-genius');

  const SIDEBAR_ITEMS: SidebarItemProps[] = [
    {
      name: t('general.common.dashboard'),
      route: routes.DASHBOARD_START,
      icon: <DashboardIcon />
    },
    {
      name: t('general.common.designs'),
      route: routes.PUBLIC_DESIGNS,
      icon: <DesignsIcon />,
      beta: true
    },
    {
      name: t('general.common.projects'),
      route: routes.PROJECTS_LIST,
      icon: <ProjectsIcon />
    },
    {
      name: t('general.common.renders'),
      route: routes.RENDERS_LIST,
      icon: <RendersIcon />
    },
    {
      name: t('general.common.integrations'),
      route: routes.INTEGRATIONS,
      icon: <IntegrationsIcon />,
      beta: true
    },
    {
      name: t('general.common.settings'),
      route: routes.ORGANIZATION_SETTINGS,
      icon: <SettingsIcon />
    }
  ];

  const isNewReleaseAvailable = releaseNotesHash && settings.latestViewedReleaseNotesVersion !== releaseNotesHash;

  const releaseNotesBase = `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html`;
  const releaseNotesLink = isNewReleaseAvailable
    ? `${releaseNotesBase}#v${releaseNotesHash}`
    : `${releaseNotesBase}#releases`;

  const DOC_ITEMS = [
    {
      name: t('components.layout.sidebar.Sidebar.plugin'),
      href: PLAINLY_PLUGIN_CREATIVE_CLOUD_LINK,
      showNotification: !settings.pluginViewed,
      clearNotification: () => setPluginViewed(true)
    },
    {
      name: t('components.layout.sidebar.Sidebar.videoTutorials'),
      href: 'https://plainlyvideos.com/quick-start'
    },
    {
      name: t('components.layout.sidebar.Sidebar.userManual'),
      href: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html`
    },
    {
      name: t('components.common.apiReference'),
      href: isProd ? 'https://plainlyvideos.com/documentation/api-reference' : `/api-reference.html`
    },
    {
      name: t('components.common.releaseNotes'),
      href: releaseNotesLink,
      showNotification: isNewReleaseAvailable,
      clearNotification: () => setTimeout(() => setLatestViewedReleaseNotes(releaseNotesHash), 3000)
    },
    {
      name: t('components.layout.sidebar.Sidebar.contactSupport'),
      href: 'https://plainlyvideos.com/contact/'
    }
  ];

  const PUBLISHER_VIEW_ITEMS = [
    {
      name: t('general.common.articles'),
      route: routes.ARTICLES,
      icon: <ArticleIcon />
    },
    {
      name: t('general.common.brands'),
      route: routes.BRANDS,
      icon: <BrandsIcon />
    }
  ];

  return (
    <div className="h-0 flex-1 overflow-y-auto">
      <nav className="mt-5 flex-1 space-y-8 px-2" aria-label="Sidebar">
        {!sidebarDesktopFullyCollapsed && (
          <>
            <div className="space-y-1">
              {publisherView ? (
                <>
                  {PUBLISHER_VIEW_ITEMS.map(item => (
                    <SidebarItem
                      key={item.name}
                      {...item}
                      isActive={location.pathname.indexOf(item.route) >= 0}
                      onClick={toggleSideBar}
                    />
                  ))}
                </>
              ) : (
                <>
                  {SIDEBAR_ITEMS.map(item => (
                    <SidebarItem
                      key={item.name}
                      {...item}
                      isActive={location.pathname.indexOf(item.route) >= 0}
                      onClick={toggleSideBar}
                    />
                  ))}
                </>
              )}
            </div>

            <div className="space-y-1">
              <h3
                className="px-3 text-xs font-semibold uppercase tracking-wider text-gray-500"
                id="documentation-headline"
              >
                {t('components.layout.sidebar.Sidebar.switchMode')}
              </h3>
              <div className="space-y-1">
                <Link
                  to={publisherView ? routes.DASHBOARD : routes.VIDEO_GENIUS}
                  className="group flex items-center rounded-md p-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                  onClick={toggleSideBar}
                >
                  <ArrowPath className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300" />
                  <span>
                    {publisherView
                      ? t('components.layout.sidebar.Sidebar.basicView')
                      : t('components.layout.sidebar.Sidebar.videoGeniusView')}
                  </span>
                  {!publisherView && (
                    <div className="ml-1 flex h-5 w-5 items-center justify-center rounded border border-gray-300 group-hover:border-white">
                      <span className="text-xs">AI</span>
                    </div>
                  )}
                </Link>
              </div>
            </div>

            <div className="space-y-1">
              <h3
                className="px-3 text-xs font-semibold uppercase tracking-wider text-gray-500"
                id="documentation-headline"
              >
                {t('components.common.resources')}
              </h3>
              <div className="flex flex-col space-y-1" role="group" aria-labelledby="documentation-headline">
                {DOC_ITEMS.map(item => (
                  <a
                    key={`key-${item.name}`}
                    href={item.href}
                    target="_blank"
                    rel="noreferrer"
                    className="group relative flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    onClick={() => {
                      if (item.showNotification) {
                        item.clearNotification();
                      }
                    }}
                  >
                    <span className="truncate">{item.name}</span>
                    {item.showNotification && (
                      <div className="flex-none rounded-full bg-indigo-400/10 p-1 text-indigo-400">
                        <div className="h-2 w-2 rounded-full bg-current" />
                      </div>
                    )}
                  </a>
                ))}
              </div>
            </div>
          </>
        )}
        {sidebarDesktopFullyCollapsed && (
          <>
            <div className="space-y-1">
              {publisherView ? (
                <>
                  {PUBLISHER_VIEW_ITEMS.map(item => (
                    <SidebarItem
                      key={item.name}
                      {...item}
                      isActive={location.pathname.indexOf(item.route) >= 0}
                      onClick={toggleSideBar}
                      sidebarDesktopFullyCollapsed={sidebarDesktopFullyCollapsed}
                    />
                  ))}
                </>
              ) : (
                <>
                  {SIDEBAR_ITEMS.map(item => (
                    <SidebarItem
                      key={item.name}
                      {...item}
                      isActive={location.pathname.indexOf(item.route) >= 0}
                      onClick={toggleSideBar}
                      sidebarDesktopFullyCollapsed={sidebarDesktopFullyCollapsed}
                    />
                  ))}
                </>
              )}
            </div>

            <div>
              <Link
                to={publisherView ? routes.DASHBOARD : routes.VIDEO_GENIUS}
                className="group mt-[52px] flex items-center rounded-md p-2 text-sm font-medium hover:bg-gray-700"
                onClick={toggleSideBar}
              >
                <ArrowPath
                  title={
                    publisherView
                      ? t('components.layout.sidebar.Sidebar.basicView')
                      : t('components.layout.sidebar.Sidebar.videoGeniusView')
                  }
                  className="h-6 w-6 text-gray-400 group-hover:text-gray-300"
                />
              </Link>
            </div>
          </>
        )}
      </nav>
    </div>
  );
});

export default SidebarLinks;
