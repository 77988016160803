import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

export const UserMailDuplicatePage = ({ message }: { message?: string }) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const executeLogout = useCallback(() => logout({ logoutParams: { returnTo: window.location.origin } }), [logout]);

  return (
    <div className="bg-white">
      <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
        <img className="mx-auto h-10 w-auto sm:h-12" src="/assets/favicon/favicon-96x96.png" alt="Plainly Logo" />
        <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <p className="text-base font-semibold uppercase leading-8 text-indigo-600">
            {t('general.common.welcomeMessage')}
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {t('components.UserMailDuplicatePage.title')}
          </h1>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">{message}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a href="#" onClick={executeLogout} className="text-sm font-semibold text-gray-900">
              {t('general.action.logout')}
              <span aria-hidden="true" className="ml-1">
                &rarr;
              </span>
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};
