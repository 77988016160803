import React from 'react';
import classNames from 'classnames';

import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

type ParamsListItem = {
  title: string | React.JSX.Element;
  description?: string;
};

export const ParamsList = ({
  title,
  items,
  wide
}: {
  title: string;
  items: string[] | ParamsListItem[];
  wide?: boolean;
}) => {
  return (
    <div className={classNames(wide ? 'sm:col-span-2' : 'sm:col-span-1')}>
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <ul className="mt-1 flex list-inside list-disc flex-col text-sm text-gray-900">
        {items.map((item, index) => {
          if (typeof item === 'string') {
            return <li key={index}>{item}</li>;
          } else {
            return (
              <li key={index}>
                <span>{item.title}</span>
                {item.description && (
                  <QuestionMarkCircleIcon
                    className="ml-2 inline-block h-5 w-5 cursor-help stroke-2 text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                    title={item.description}
                  />
                )}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
