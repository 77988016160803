import { encode } from 'js-base64';

import { AeItemType, LayerEffectType } from '@plainly/types';

import localizationHelper from './i18n';
import { AbstractRenderDto, CompositionAeItem, DesignParameter, DesignParameterType, Layer, LayerType } from './models';

/**
 * Validates the HEX color string.
 *
 * @param hex The HEX color string in the format #RRGGBBAA, #RRGGBB, #RGBA, or #RGB.
 */
export const isValidHexColor = (hex: string): boolean => {
  const finalHex = !hex.startsWith('#') ? `#${hex}` : hex;
  if (
    !finalHex ||
    (!/^#([A-Fa-f0-9]{8})$/.test(finalHex) &&
      !/^#([A-Fa-f0-9]{6})$/.test(finalHex) &&
      !/^#([A-Fa-f0-9]{4})$/.test(finalHex) &&
      !/^#([A-Fa-f0-9]{3})$/.test(finalHex))
  ) {
    return false;
  }

  return true;
};

export const isEmpty = <T>(list: T[] | null | undefined): list is undefined | null | [] => {
  return !list || (list && list.length === 0);
};

export const isEmptyObj = (obj?: { [key: string]: unknown }): obj is undefined | { [key: string]: unknown } => {
  return Object.keys(obj || {}).length === 0;
};

export const toMb = (size: number) => size / (1024 * 1024);

export const randId = () => Math.random().toString(36).slice(2, 11);

export const tryFormatJSON = (input: string): string => {
  try {
    return JSON.stringify(JSON.parse(input), null, 2);
  } catch {
    return input;
  }
};

export function debounce<T extends unknown[]>(func: (...args: T) => void, wait: number): (...args: T) => void {
  let timeoutId: NodeJS.Timeout | null = null;

  return (...args: T): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func(...args), wait);
  };
}

export const toApiParameterName = (value: string): string => {
  // Remove '#' from the beginning
  return value.substring(value.indexOf('#') === 0 ? 1 : 0);
};

export const getParameterType = (layer: Partial<Layer>) => {
  switch (layer.layerType) {
    case LayerType.DATA:
      return DesignParameterType.STRING;
    case LayerType.DATA_EFFECT:
      switch (layer.effectType) {
        case LayerEffectType.EFFECT_COLOR_CONTROL:
          return DesignParameterType.COLOR;
        case LayerEffectType.EFFECT_SLIDER_CONTROL:
          return DesignParameterType.NUMBER;
      }
      return DesignParameterType.STRING;
    case LayerType.MEDIA:
      return DesignParameterType.MEDIA;
    case LayerType.SOLID_COLOR:
      return DesignParameterType.COLOR;
  }
};

export const layerToParameter = (layer: Partial<Layer>): DesignParameter | undefined => {
  const type = layer.layerType ? getParameterType(layer) : null;
  const getDescription = () => {
    switch (type) {
      case DesignParameterType.COLOR:
        return 'Select a color from the picker or provide a HEX color code.';
      case DesignParameterType.MEDIA:
        return 'Provide URL to the asset or upload it directly.';
      default:
        return undefined;
    }
  };

  if (layer.parametrization?.expression && type) {
    let defaultValue;
    switch (type) {
      case DesignParameterType.NUMBER:
        defaultValue = layer.parametrization.defaultValue ? parseFloat(layer.parametrization.defaultValue) : undefined;
        break;
      default:
        defaultValue = layer.parametrization.defaultValue;
    }

    return {
      key: toApiParameterName(layer.parametrization.value),
      type: type,
      name: layer.label || toApiParameterName(layer.parametrization.value),
      optional: !layer.parametrization.mandatory,
      defaultValue,
      description: getDescription()
    } as DesignParameter;
  }
};

// adds advanced options to render
export function addAdvancedOptions<T extends AbstractRenderDto>(render: T, advancedOptions?: AbstractRenderDto): T {
  if (!advancedOptions) {
    return render;
  }

  let newRender: T = {
    ...render,
    outputFormat: advancedOptions.outputFormat || render.outputFormat
  };

  if (advancedOptions?.webhook?.url) {
    newRender = {
      ...newRender,
      webhook: advancedOptions.webhook
    };
  }

  if (advancedOptions?.options) {
    newRender = {
      ...newRender,
      options: advancedOptions.options
    };
  }
  return newRender;
}

const loremIpsum = () => {
  const loremIpsumSentences = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
    'Duis aute irure dolor in reprehenderit in voluptate velit esse.',
    'Excepteur sint occaecat cupidatat non proident.'
  ];

  return loremIpsumSentences[Math.floor(Math.random() * loremIpsumSentences.length)];
};

// returns random example value for render form field
export const getSampleData = (parameter: DesignParameter) => {
  if (parameter.sampleValue || parameter.sampleValue === '') return parameter.sampleValue;
  else {
    switch (parameter.type) {
      case DesignParameterType.STRING:
        return loremIpsum();
      case DesignParameterType.MEDIA:
        return 'https://picsum.photos/1920/1920';
      case DesignParameterType.BOOLEAN:
        return true;
      case DesignParameterType.NUMBER:
        return Math.random();
      case DesignParameterType.COLOR: {
        const color = `#${Math.floor(100000 + Math.random() * 900000)}`;
        return color;
      }
    }
  }
};

export const isValidUrl = (url: string | undefined) => {
  if (!url) return false;
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const omitKeys = <T extends { [key: string]: unknown }>(obj: T, keys: string[]) => {
  const result = { ...obj };
  keys.forEach(key => delete result[key]);
  return result;
};

export const transformNullsToUndefined = <T>(obj: T): T => {
  const deepCopy = JSON.parse(JSON.stringify(obj)) as T;

  const transform = <U>(currentObj: U): U => {
    if (currentObj === null) return undefined as unknown as U;
    if (typeof currentObj !== 'object' || currentObj === null) return currentObj;
    if (Array.isArray(currentObj)) return currentObj.map(transform) as unknown as U;
    return Object.fromEntries(
      Object.entries(currentObj as Record<string, unknown>).map(([key, value]) => [key, transform(value)])
    ) as unknown as U;
  };

  return transform(deepCopy);
};

export const deepEqual = (a: unknown, b: unknown): boolean => {
  if (a === b) {
    return true;
  }
  if (typeof a !== 'object' || typeof b !== 'object' || a === undefined || b === undefined) {
    return false;
  }
  const keysA = Object.keys(a as Record<string, unknown>);
  const keysB = Object.keys(b as Record<string, unknown>);
  if (keysA.length !== keysB.length) {
    return false;
  }
  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual((a as Record<string, unknown>)[key], (b as Record<string, unknown>)[key])) {
      return false;
    }
  }
  return true;
};

export const undefinedIfNull = <T>(value?: T) => (value !== null ? value : undefined);

export const countCompChildren = (comp: CompositionAeItem): number => {
  if (!comp.children || comp.children.length === 0) {
    return 0;
  }

  let count = comp.children.length;
  comp.children.forEach(child => {
    if (child.type === AeItemType.COMPOSITION) {
      count += countCompChildren(child as CompositionAeItem);
    }
  });

  return count;
};

/**
 * Flattens an object to a dot notation object
 * @param object object to flatten
 * @param prefix prefix to add to the keys
 * @returns flattened object
 */
export function flattenObject(ob: { [key: string]: unknown }, prefix: string = ''): { [key: string]: unknown } {
  return Object.keys(ob).reduce((p: { [key: string]: unknown }, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof ob[k] === 'object' && ob[k] !== null) {
      Object.assign(p, flattenObject(ob[k] as { [key: string]: unknown }, pre + k));
    } else {
      p[pre + k] = ob[k];
    }
    return p;
  }, {});
}

/**
 * Converts dot notation keys to nested objects
 * @param object object to convert
 * @returns new object with nested keys
 */
export function convertDotsToNested(object: { [key: string]: unknown }): { [key: string]: unknown } {
  return Object.entries(object).reduce((acc: { [key: string]: unknown }, [key, value]) => {
    if (key.includes('.')) {
      const keys = key.split('.');
      let currentLevel = acc;
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!currentLevel[key]) {
          currentLevel[key] = i === keys.length - 1 ? value : {};
        }
        if (i !== keys.length - 1) {
          currentLevel = currentLevel[key] as { [key: string]: unknown };
        }
      }
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}

/**
 * Generates batchRenderId in format: <date>|<filename>
 * @returns a new batch render id
 */
export function generateBatchRenderId(filename: string) {
  const dateNow = new Date().toISOString();
  return `${dateNow}|${filename}`;
}

export const compositionSorter = (a: CompositionAeItem, b: CompositionAeItem) => {
  const aLayersCount = countCompChildren(a);
  const bLayersCount = countCompChildren(b);
  // Secondary, if children layers count is equal, sort by duration
  if (bLayersCount === aLayersCount) {
    return b.duration - a.duration;
  }

  // Primary sort by number of child layers
  return bLayersCount - aLayersCount;
};

export const collectCompositions = (composition: CompositionAeItem, maxLevel: number = Infinity, currentLevel = 0) => {
  const res: CompositionAeItem[] = [composition];
  if (currentLevel >= maxLevel) {
    return res;
  }

  composition.children.forEach(element => {
    if (element.type === AeItemType.COMPOSITION) {
      const children = collectCompositions(element as CompositionAeItem, maxLevel, currentLevel + 1);
      children?.sort(compositionSorter).forEach(e => res.push(e));
    }
  });

  return res;
};

export const ensureColorHex = (value?: string) => {
  if (!value || value.startsWith('#')) {
    return value;
  }
  return `#${value}`;
};

export const downloadFile = async (
  file: string,
  fileName?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  directoryHandle?: { getFileHandle: (arg0: string, arg1: { create: boolean }) => any }
) => {
  const response = await fetch(file);
  const blob = await response.blob();
  const name = fileName || file.split('/').pop();

  if (!name) return;

  if (directoryHandle) {
    // if we have directory handle, save the file to the selected directory
    const fileHandle = await directoryHandle.getFileHandle(name, { create: true });
    const writable = await fileHandle.createWritable();

    // try write and close always
    try {
      await writable.write(blob);
    } finally {
      await writable.close();
    }
  } else {
    downloadBlob(blob, name);
  }
};

export const downloadBlob = (blob: Blob, fileName: string) => {
  // Create a temporary anchor element
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  // Append the anchor to the body
  document.body.appendChild(a);
  // Programmatically click the anchor
  a.click();
  // Remove the anchor from the body
  a.remove();
};

/**
 * Converts organization name to initials
 * @example organizationInitials('') => 'O'
 * @example organizationInitials('My organization') => 'M'
 * @example organizationInitials('My Organization') => 'MO'
 * @example organizationInitials('My Organization Name') => 'MO'
 * @example organizationInitials('My small Name') => 'MN'
 * @example organizationInitials('my org') => 'M'
 * @param {string} name Name of the organization
 * @returns {string} Organization initials
 */
export const organizationInitials = (name?: string): string => {
  if (!name) {
    return 'O';
  }

  const words = name
    .trim()
    .split(' ')
    .filter(word => word !== '');

  if (words.length > 1) {
    // add two uppercase letters max
    const uppercaseLetters = [];
    for (let i = 0; i < words.length; i++) {
      if (uppercaseLetters.length === 2) {
        break;
      }

      if (words[i][0] === words[i][0].toUpperCase()) {
        uppercaseLetters.push(words[i][0]);
      }
    }

    // if uppercase letters found, return them
    if (!isEmpty(uppercaseLetters)) {
      return uppercaseLetters.join('');
    }
  }

  return words[0][0].toUpperCase();
};

/**
 * Returns the initials of a given username.
 *
 * @param {string} username - The username to extract initials from.
 * @return {string} The initials of the username. If the username is undefined or empty, returns 'U'.
 * If the username has only one character, returns the uppercase version of that character.
 * If the username has two or more characters, returns the uppercase combination of the first two characters.
 */
export const usernameInitials = (username?: string): string => {
  if (!username) return 'U';
  if (username.length === 1) return username[0].toUpperCase();
  return (username[0] + username[1]).toUpperCase();
};

/**
 * Converts a display name to a parameter name by removing leading digits,
 * splitting into words, and converting to camel case.
 *
 * @param {string} displayName - The display name to be converted.
 * @return {string} The converted parameter name.
 */
export const displayToParameterName = (displayName: string): string => {
  // Important!
  // run `displayToParameterName.test` after changing this function
  const parameterName = displayName;
  const noLeadingDigits = parameterName.replace(/^[\d\s]+/, '');
  const words = noLeadingDigits.split(/[\s@.!?$_=+-]+/);

  if (words.length === 1 && words[0].length > 0) {
    return noLeadingDigits.charAt(0).toLocaleLowerCase() + noLeadingDigits.slice(1);
  }

  const lowercaseWords = words.map(w => w.toLowerCase());
  const camelCaseWords = lowercaseWords
    .filter(w => w.length > 0)
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toLocaleLowerCase() + word.slice(1);
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

  return camelCaseWords.join('');
};

/**
 * Encodes a string to base64
 * @param {unknown} data
 * @returns {string} Base64 encoded string
 */
export const encodeJSON = (data: unknown): string => encode(JSON.stringify(data));

/**
 * safe way to check if array includes value, supporting undefined values
 *
 * @param array array to check
 * @param value value to check
 * @returns returns false if value is undefined, otherwise returns array.includes(value)
 */
export const includesSafe = <T>(array: T[], value?: T): boolean => {
  if (value) {
    return array.includes(value);
  }
  return false;
};

/**
 * Creates a curl command from a given JSON body and a URL.
 *
 * @param {string} curlBody The JSON body to send in the request.
 * @param {string} url The URL to send the request to.
 * @param {string} [apiKey] The API key to use for authentication. If not provided, a placeholder
 *    string will be inserted.
 * @returns {string} A string representing a valid curl command.
 */
export const makeCurl = (curlBody: string, url: string, apiKey?: string): string => {
  const apiFinal = apiKey ? apiKey : '[YOUR_API_KEY]';

  const niceCurlBody = curlBody
    .split('\n')
    .map(line => line.replace(/'/g, `'\\''`)) // escape single quotes for curl
    .map((line, index) => (index > 0 ? `      ${line}` : line))
    .join('\n');

  let cmd = `curl -X POST \\`;
  cmd += `\n  -H "Content-Type: application/json" \\`;
  cmd += `\n  -u ${apiFinal}: \\`;
  cmd += `\n  -d '${niceCurlBody}' \\`;
  cmd += `\n  ${url}`;

  return cmd;
};

/**
 * Format a resource usage.
 *
 * @param {number} usage The number for the usage.
 * @returns {string} A string representing the given resource usage.
 */
export const formatResourceUsage = (usage: number, maximumFractionDigits = 1): string => {
  return localizationHelper.forNumber({ style: 'decimal', maximumFractionDigits }).format(usage);
};

/**
 * Checks if the given value is a valid number.
 *
 * @param {number | undefined | null} number - The value to check for validity.
 * @returns {boolean} True if the value is a valid number, otherwise false.
 */
export const isValidNumber = (number: number | undefined | null): number is number => {
  return number !== null && number !== undefined && !isNaN(number);
};
